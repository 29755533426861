import React from "react";

const Hero = ({ className, ...rest }) => {
  return (
    <div className={className} {...rest}>
    <div className="pt-4 pt-md-6 pt-lg-10 ">
      <div className="container">
        {/* Section Padding */}
        <div className="row justify-content-center">
          <div className="col-xl-7 col-lg-8 col-md-11">
            <div
              className="text-center mb-11 mb-lg-15"
              data-aos="zoom-in"
              data-aos-duration={500}
              data-aos-delay={500}
            >
              <h1 className="font-size-10 mb-5">Make Learning with video an engaging experience</h1>
              <h2 className="font-size-7 mb-0">
              Add content to your videos so students can interact, download and learn more.
              </h2>
            </div>
          </div>
        </div>
        {/* End Section Padding */}
      </div>
    </div>
    </div>
  );
};

export default Hero;
