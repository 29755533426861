import React from "react";
import imgM from "../../assets/image/hero/learning.png";

const Content1 = ({ className, ...rest }) => {
  return (
    <div className={className} {...rest}>
      <div className="container">
        <div className="row justify-content-center justify-content-lg-start">
          {/* Image Section */}
          <div className="col-xl-4 col-xs-4">
            <div>
              <div className="img-2">
                <img
                  className="w-100"
                  src={imgM}
                  alt="Digma Interactive Learning"
                  data-aos="fade-right"
                  data-aos-delay={500}
                />
              </div>
            </div>
          </div>
          {/* Content Section */}
          <div className="col-lg-7 col-md-8 col-xs-10 ml-lg-10 ml-xl-10">
            <div className="pt-7 pb-7 pb-lg-17 pl-sm-5 pl-md-9 pl-lg-10">
              <h3
                className="font-size-11 mb-9 pr-xs-23 pr-sm-8 pr-md-18 pr-lg-18 "
                data-aos="fade-up"
                data-aos-delay={300}
              >
                Watch and learn
              </h3>
              <p
                className="font-size-7 mb-0 pr-xl-13"
                data-aos="fade-up"
                data-aos-delay={600}
              >Together we can revolutionize the concept of online learning. Improve communications with your student body by making all your video content completely interactive.</p>
              <p className="font-size-7 mt-10 pr-xl-13" data-aos="fade-up"
                data-aos-delay={600}> Thanks to our own up to date technological advancements, our cutting edge and unmistakable overlays are the most responsive and resourceful on the market. Empowering, accessible, and highly versatile, DIGMA is ideal for online courses and webinars.  
              </p>                  
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content1;
