import React from "react";
import {Link} from "gatsby";
import { Helmet } from "react-helmet";
import PageWrapper from "../../components/PageWrapper";
import Hero from "../../sections/learning/Hero";
import Wave from "../../sections/common/Wave";
import Content1 from "../../sections/learning/Content1";
import VideoDemo from "../../sections/common/VideoDemoNew";
import SEO from '../../components/SEO';
import imgC from "../../assets/image/hero/learning.png";

const PricingPage = () => {
  return (
    <>
      <PageWrapper        
        themeConfig={{
          headerClassName: "site-header--menu-left",
          headerFluid: false,

          footerStyle: "digma",
        }}>
        <SEO
          title="Make Learning with video an engaging experience"
          description="Add content to your videos so students can interact, download and learn more"
          image={imgC}
        />             
         <div className="container pt-20 pt-md-16 pt-lg-20 ">
          <nav className="breadcrumb" aria-label="Breadcrumb">
            <ol className="breadcrumb__list">
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/">Home</Link>
                <span className="breadcrumb__separator" aria-hidden="true"> / </span>
              </li>
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/industries">Industries</Link>
                <span className="breadcrumb__separator" aria-hidden="true"> / </span>
              </li>
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/industries/learning">Learning</Link>
              </li>              
              </ol>
           </nav>
        </div>        
        <Helmet>
        <link
          href={'https://cdn.digma.io/dist/digma.min.2.0.0.css?v=learning'}
          rel="stylesheet"
          />
          <script async src={'https://cdn.digma.io/dist/digma.min.2.0.0.js?v=learning'}></script>         
         
        </Helmet>                 
        <Hero />
        <VideoDemo id="demo" title="" name="" projectId="F8E0FDB3-8FA7-4ED7-8655-0BA12534B773" />
        <Wave color="#F7F9FC" /> 
        <Content1 className="grey pt-10 pt-md-20 pt-lg-15 pb-9 pb-md-14 pb-lg-35 overflow-hidden position-relative" />
      </PageWrapper>
    </>
  );
};
export default PricingPage;
